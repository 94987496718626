<template>
  <td v-if="performance && performance.audio" :style="{'text-align': 'center', 'background-color': performance.audio_confirm ? '#00b20e' : '', 'color': 'white'}" :id="'audio-block-' + performance.id">

    <p v-if="performance.audio_confirm" style="cursor: pointer; ">Музыка подтверждена, замена трека
      заблокирована</p>
    <center>
      <a :href="'https://appdemo.inform-s.ru/api/download-audio?id=' + performance.id"
         style="color: #000000; cursor: pointer;">скачать музыку</a>
    </center>
    <audio controls>
      <source :src="performance.audio.path" type="audio/ogg">
      <source :src="performance.audio.path" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
    <br/>
    <ui-button v-if="performance.audio_confirm" style="color: white; background-color: #990066; cursor: pointer;"
               @click="disableConfirmAudio(performance)">Разблокировать загрузку музыки
    </ui-button>
    <ui-button v-else style="color: white; background-color: #990066; cursor: pointer;" @click="confirmAudio">
      Подтвердить музыку и заблокировать отправку
    </ui-button>

  </td>
  <td v-else style="text-align: center;">
    <ui-button v-if="performance.audio_access" @click="disableAccessAudioById(performance)"
               :style="{'color': 'white',  'background-color': performance.audio_access ? 'red' : '', 'cursor': 'pointer'}">
      Заблокировать загрузку музыки
    </ui-button>
    <ui-button v-else @click="accessAudioById(performance)"
               style="color: white; background-color: #0391af; cursor: pointer;">
      Разблокировать загрузку музыки
    </ui-button>

  </td>
</template>

<script>
import axios from "axios";


export default {

  props: ['performance'],

  data() {
    return {
      performanceLocal: this.performance,
    };
  },
  methods: {
    disableAccessAudioById() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-access-audio-by-id`, {'performance_id': this.performance.id})
          .then(() => {
            this.performanceLocal.audio_access = false;
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/update-audio-access`, {
              'performance_id': this.performanceLocal.id,
              'audio_access': this.performanceLocal.audio_access,
            })
                .then(() => {
                  console.log("Значение audioConfirm успешно обновлено в базе данных");
                  this.$forceUpdate();
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    accessAudioById() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/access-audio-by-id`, {'performance_id': this.performance.id})
          .then(() => {
            this.performanceLocal.audio_access = true;
            this.$forceUpdate();
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/update-audio-access`, {
              'performance_id': this.performanceLocal.id,
              'audio_access': this.performanceLocal.audio_access,
            })
                .then(() => {
                  console.log("Значение audioConfirm успешно обновлено в базе данных");
                  this.$forceUpdate();
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    confirmAudio() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/audio-confirm`, {'performance_id': this.performance.id})
          .then(() => {
            this.performanceLocal.audio_confirm = true;
            this.$forceUpdate();
            // Обновление значения в базе данных
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/update-audio-confirm`, {
              'performance_id': this.performanceLocal.id,
              'audio_confirm': this.performanceLocal.audio_confirm
            })
                .then(() => {
                  console.log("Значение audioConfirm успешно обновлено в базе данных");
                  this.$forceUpdate();
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    disableConfirmAudio() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-audio-confirm`, {'performance_id': this.performance.id})
          .then(() => {
            this.performanceLocal.audio_confirm = false;
            this.$forceUpdate();

            // Обновление значения в базе данных
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/update-audio-confirm`, {
              'performance_id': this.performanceLocal.id,
              'audio_confirm': this.performanceLocal.audio_confirm
            })
                .then(() => {
                  console.log("Значение audioConfirm успешно обновлено в базе данных");
                  this.$forceUpdate();
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
  }
}
</script>

<style scoped>


</style>