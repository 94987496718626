<template>
    <div>
        <!-- Отображение существующих таблиц -->
        <div v-for="(table, index) in tables" :key="index" class="tyming">
            <div v-if="table.type !== 'break'">
                <div class="group">
                    <h2>{{ table.projectile }} - {{ table.nomination }} - {{ table.level }}</h2>
                    <div class="remove-button">
                        <ui-button @click="removeTable(index)" raised class="delete-btn">Удалить таблицу</ui-button>
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Время</th>
                        <th>Номер</th>
                        <th>ФИО</th>
                        <th>Город</th>
                        <th>Соло/Дуэт</th>
                        <th>Уровень</th>
                        <th>Номинация</th>
                        <th>Снаряд</th>
                        <th>Категория</th>
                        <th>Музыка</th>
                        <th>Фото</th>
                        <th>Видео</th>
                        <th>Свет</th>
                        <th>Текст ведущему</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(perf, perfIndex) in table.data" :key="perfIndex">
                        <td>{{ getTime(index, perfIndex) }}</td>
                        <td>{{ perf.participantNumber }}</td>
                        <td>
                            {{ perf.user.name }} {{ perf.user.surname }} {{ perf.user.patronymic }}<br>
                            {{ perf.patner_fio || '' }}
                        </td>
                        <td>{{ perf.user.city }}</td>
                        <td>{{ perf.solo_or_duet }}</td>
                        <td>{{ perf.level }}</td>
                        <td>{{ perf.nomination }}</td>
                        <td>{{ perf.projectile.title }}</td>
                        <td>{{ perf.age_category }}</td>
                        <td>{{ perf.audio }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{{ perf.leader_text }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="break">
                <span>{{ table.timeout }} минут перерыв/проба площадки</span>
                <div class="remove-button">
                    <ui-button @click="removeBreak(index)" raised>Удалить перерыв</ui-button>
                </div>
            </div>
        </div>
    </div>

    <div class="down-header">
        <div style="margin-bottom: 20px;">
            <ui-button @click="addBreak" raised>Добавить перерыв (проба площадки)</ui-button>
            <vue-timepicker v-model="breakTime" :minute-interval="5" format="mm" placeholder="время в минутах"></vue-timepicker>
        </div>
        <br>
        <div class="select-container">
            <label id="find">Уровень подготовки</label>
            <select id="find" v-model="newTableLevel">
                <option v-for="level in levels" :key="level" :value="level">{{ level }}</option>
            </select>
        </div>

        <div class="select-container">
            <label id="find">Номинация</label>
            <select id="find" v-model="newTableNomination">
                <option v-for="nomination in nominations" :key="nomination" :value="nomination">{{ nomination }}</option>
            </select>
        </div>

        <div class="select-container">
            <label id="find">Снаряд</label>
            <select id="find" v-model="newTableProjectile">
                <option v-for="projectile in projectiles" :key="projectile" :value="projectile">{{ projectile }}</option>
            </select>
        </div>
        <TimePicker v-model="lastTime" :last-time="lastTime"></TimePicker>
        <ui-button @click="addTable(newTableLevel, newTableNomination, newTableProjectile)" raised>Добавить таблицу</ui-button>

        {{lastTime}}
    </div>
</template>
<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import axios from "axios";
import TimePicker from "@/components/TimePicker.vue";

const levels = [
    'Дебют',
    'Начинающие',
    'Продвинутые',
    'Профессионалы',
    'Элита',
];

const projectiles = [
    'Пилон',
    'Полотно',
    'Кольцо',
    'Стропы',
    'Петля(гамак)',
    'Оригинальный снаряд',
    'Бортик',
];

const nominations = ['Арт', 'Спорт', 'Exotic pole'];
export default {
    components: {
        TimePicker,
        VueTimepicker
    },
    data() {
        return {
            nominations,
            projectiles,
            levels,
            tyming: [],
            tables: [],
            time: '10:00',
            breakTime: '',
            data: [],
            performanceArr: [],
            selectedLevel: null,
            selectedNomination: null,
            selectedProjectile: null,
            newTableLevel: 'Начинающие',
            newTableNomination: 'Спорт',
            newTableProjectile: 'Полотно',
            currentParticipantNumber: 1,
            lastTime: '10:00',
        };
    },
    created() {
        this.performances()
    },
    methods: {
        updateLastTime() {

        },
        removeTable(index) {
            this.tables.splice(index, 1);
        },

        removeBreak(index) {
            this.tables.splice(index, 1);
        },

        addTable(level, nomination, projectile) {
            const filteredData = this.data
                .filter(item => item.level === level)
                .filter(item => item.nomination === nomination)
                .filter(item => item.projectile.title === projectile)
                .sort((a, b) => {
                    if (a.solo_or_duet < b.solo_or_duet) return 1;
                    if (a.solo_or_duet > b.solo_or_duet) return -1;

                    if (a.age_category < b.age_category) return 1;
                    if (a.age_category > b.age_category) return -1;

                    if (a.date_of_birth > b.date_of_birth) return -1;
                    if (a.date_of_birth < b.date_of_birth) return 1;
                    return 0;
                })
                .map((item, index) => ({
                    ...item,
                    participantNumber: this.currentParticipantNumber + index // Increasing participant number for each data item
                }));

            if (filteredData.length === 0) {
                return;
            }

            this.currentParticipantNumber += filteredData.length;

            let currentTime = new Date();
            currentTime.setHours(this.lastTime.split(':')[0]);
            currentTime.setMinutes(this.lastTime.split(':')[1]);

            let hours = currentTime.getHours();
            let minutes = currentTime.getMinutes();

            let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

            this.tables.push({
                level,
                nomination,
                projectile,
                data: filteredData,
                startTime: formattedTime, // Устанавливаем начальное время для новой таблицы
            });
        },

        addBreak() {
            const breakTime = parseInt(this.breakTime, 10); // Преобразуем breakTime в число

            // Добавляем перерыв в таблицы
            this.tables.push({
                type: 'break',
                timeout: this.breakTime
            });

            // Если lastTime не определено, используем начальное время
            let lastTime = this.lastTime || '00:00';

            // Разбиваем lastTime на часы и минуты
            let [lastHours, lastMinutes] = lastTime.split(':').map(Number);

            // Добавляем минуты к существующему времени
            const newMinutes = (lastMinutes + breakTime) % 60;
            const additionalHours = Math.floor((lastMinutes + breakTime) / 60);

            // Добавляем дополнительные часы к существующим часам
            const newHours = (lastHours + additionalHours) % 24;

            // Форматируем новое время
            const newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;

            // Обновляем lastTime
            this.lastTime = newTime;
            return newTime;
        },
        async performances() {
            const url = `${process.env.VUE_APP_DOMAIN}/api/performances`;

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            };
            this.performanceArr = this.performanceUpdated
            await axios.get(url, config)
                .then((res) => {
                    console.log(res);
                    this.data = res.data;
                    this.performanceArr = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getTime(tableIndex, perfIndex) {
            const interval = 5;
            const table = this.tables[tableIndex];
            const startTime = table.startTime;
            const minutesToAdd = interval * perfIndex;

            const [hours, minutes] = startTime.split(':').map(Number);

            const newMinutes = (minutes + minutesToAdd) % 60;
            const newHours = (hours + Math.floor((minutes + minutesToAdd) / 60)) % 24; // Ограничиваем часы до 24

            const time = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
            this.lastTime = time;

            return time;
        }
    }
};
</script>

<style>
  table {
      margin: 10px 0 10px 0;

  }
  th, tr, td {
      border-bottom: 2px solid black;
  }
  button {
      margin-right: 10px;
      margin-left: 5px;
  }
  th p {
      text-decoration: underline 1px;
      margin-top: 15px;
  }
  .break {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      width: 100%;
      height: 60px;
      font-size: 25px;
      background-color: rgba(60, 179, 113, 0.8);
      color: #ffffff;
      margin: 5px;
      text-align: center;
      padding: 0 10px;
  }

  .remove-button {
      margin-left: 10px; /* Добавляем немного отступа слева для кнопки */
  }
  .down-header {
      //position: fixed;
      padding: 10px;
      border-radius: 5px;
      background-color: rgb(218, 217, 217);
      margin-bottom: 20px;
  }

  /* Стили для селекта */
  select {
      appearance: none; /* Убираем дефолтный внешний вид */
      background-color: #f8f8f8; /* Фоновый цвет */
      border: 1px solid #ddd; /* Рамка */
      border-radius: 4px; /* Скругление углов */
      color: rgba(51, 51, 51, 0.9); /* Цвет текста */
      cursor: pointer; /* Курсор при наведении */
      font-size: 16px; /* Размер текста */
      margin: 10px; /* Убираем внешние отступы */
      padding: 10px; /* Внутренние отступы */
      width: 300px;
      transition: border-color 0.3s ease; /* Плавное изменение цвета рамки */

  }

  /* Стили для селекта при фокусе */
  select:focus {
      border-color: #aaa; /* Изменение цвета рамки при фокусе */
      outline: none; /* Убираем обводку при фокусе */
  }

  /* Стили для селекта при наведении */
  select:hover {
      border-color: #aaa; /* Изменение цвета рамки при наведении */
  }

  /* Стили для селекта при активном состоянии (когда открыт список) */
  select:active {
      border-color: #aaa; /* Изменение цвета рамки при активном состоянии */
  }

  /* Стили для селекта в disabled состоянии */
  select:disabled {
      background-color: #eee; /* Фоновый цвет при disabled состоянии */
      cursor: not-allowed; /* Курсор недоступности */
      opacity: 0.6; /* Полупрозрачность */
  }

  /* Стили для селекта в option */
  select option {
      font-size: 18px; /* Размер текста в option */
      padding: 10px; /* Внутренние отступы в option */
  }

  /* Стили для label */
  label {
      font-size: 24px; /* Размер текста */
      margin-left: 10px; /* Отступ снизу */
      color: #333; /* Цвет текста */
  }
  .select-container {
      display: inline-block;
      margin-bottom: 10px; /* Добавляем небольшой отступ между контейнерами */
  }
  #find {
      display: block;
  }
  .tyming h2 {
      text-align: center;
  }
  .group {
      display: flex;
      justify-content: space-between;
      align-items: center; /* Выравнивание по вертикали */
      width: 100%;
      height: 50px;
      background-color: #f8cc57;
      border-radius: 5px;
      padding: 0 10px; /* Добавляем отступы для содержимого */
  }

  .delete-btn {
      margin-left: 10px; /* Добавляем немного отступа слева для кнопки */
  }
</style>