<template>
  <!-- {{ this.$store.state.token }} -->
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" v-if="this.$store.state.token">
      <div class="container-fluid">
          <a class="navbar-brand" href="#">Панель администрирования</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click="filterCity()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <router-link class="action nav-link" to="/performance-list">
              Списки выступлений
            </router-link>
            <!-- <li class="nav-item">
            <router-link class="action nav-link" to="/price-confirmed">Подтвержденная цена</router-link>
          </li> -->
            <li class="nav-item">
              <router-link class="action nav-link" to="/state-panel">Управление состоянием</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="action nav-link" to="/schools">Школы</router-link>
            </li>
            <li class="nav-item">
              <router-link class="action nav-link" to="/custom-coaches">Тренера</router-link>
            </li> -->
            <li class="nav-item">
              <router-link class="action nav-link" to="/marketing">Массовая рассылка</router-link>
            </li>
              <li class="nav-item">
              <router-link class="action nav-link" to="/tayming">Тайминг</router-link>
            </li>
            <!-- <li class="nav-item">
            <router-link class="action nav-link" to="/coaches">Тренера</router-link>
          </li> -->
            <!-- <li class="nav-item">
            <router-link class="action nav-link" to="/schools">Школы</router-link>
          </li>
          <li class="nav-item">
            <router-link class="action nav-link" to="/projectiles">Снаряжения</router-link>
          </li>
          <li class="nav-item">
            <router-link class="action nav-link" to="/marketing">Маркейтинг</router-link>
          </li> -->
          </ul>
        </div>
      </div>
      <ui-button style="width: 10vh" @click="logout" raised>Выход</ui-button>
    </nav>
    <!-- <hr /> -->
    <router-view />
  </div>
</template>
<script>

export default {
  data() {
    return {
      test: localStorage.getItem('test')
    }
  },
  created() {
    console.log(process.env.VUE_APP_BOT)
    this.$store.state.token = localStorage.getItem('token') ?? null
  },
  methods: {
    filterCity() {

    },
    logout() {
      var axios = require('axios');
      var FormData = require('form-data');
      var data = new FormData();
      var context = this;

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_DOMAIN}/api/logout`,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.$store.state.token,
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          localStorage.removeItem('token')
          console.log(JSON.stringify(response.data));
          context.$store.state.token = null
          context.$router.push({ name: 'Login' });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::-webkit-scrollbar {
  display: none;
} */

:root {
  --mdc-theme-primary: #d546c4 !important;
}

.logout {
  cursor: pointer;
}

.login-form {
  width: 520px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-container {
  max-width: 800px;
}

.error-format {
  color: red;
}

/* .nav_link {
  color: red !important;
} */

.action {
  /* color: black !important; */
  text-decoration: none !important;
} 

.action:hover {
  text-decoration: none;
  cursor: pointer !important;
  color: #000 !important;
}

.title-form_text {
  text-align: center;
}

.mdc-button__label {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

.clip {
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    background: #fc0; /* Цвет фона */
    padding: 5px; /* Поля вокруг текста */
    text-overflow: ellipsis; 
}

.active {
  color: green !important;
}

.non-active {
  color: red !important;
}

.state {
  color: rgb(214, 214, 65) !important;
}

.fixed-scroll {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #000;
  /* z-index: 999; */
}

.setting_td-block:hover {
    background-color: #d0caca;
    cursor: pointer;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

.delete-btn_block {
  display: flex;
  justify-content: flex-end;
  margin: 30px;
}

.mdc-layout-grid__inner {
    grid-gap: 24px;
    display: grid;
    grid-template-columns: repeat(16,minmax(0,1fr)) !important;
    margin: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
    max-height: 0;
  }

  50% {
    transform: scale(1.3);
    max-height: 30rem;
  }

  100% {
    transform: scale(1);
    max-height: 30rem;
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
    max-height: 30rem;
  }

  50% {
    transform: scale(1.1);
    max-height: 30rem;
  }

  100% {
    transform: scale(0);
    max-height: 0;
  }
}
</style>