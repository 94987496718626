<template>

    <vue-timepicker
        v-model="watch"
        :minute-interval="5"
    ></vue-timepicker>

</template>

<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'

export default {
    components: { VueTimepicker },
    props: {
        value: {
            type: Date,
            default: '10:00'
        },
        lastTime: { // Новый пропс для дополнительной переменной
            type: String,
            default: '10:00'
        }
    },
    data() {
        return {
            time: this.value,
            watch: this.lastTime
        }
    },
    watch: {
        lastTime: { // Следите за изменениями дополнительной переменной
            immediate: true, // Сразу же выполнить обработчик при монтировании компонента
            handler(newValue) {
                // Обработка нового значения дополнительной переменной
                console.log('Новое значение дополнительной переменной:', newValue);
                // Выполните нужные вам действия с новым значением
            }
        }
    },
    methods: {
        updateTime() {
            // Пример метода, который обновляет значение watch
            this.watch = new Date(); // Обновление значения watch на текущее время
        }
    },
    mounted() {
        this.updateTime();
    }
};
</script>